.mapTools {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Map Styles Control - NEW

.controlBar {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;

  height: 24px;

  background-color: white;
  // background-color: #fff;
  color: black;
  border: 1px solid black;

  font-size: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.controlBarButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 24px;

  &:not(:last-child) {
    border-right: 1px solid black;
  }

  &:hover {
    cursor: pointer;
  }
}

.controlBarButtonActive {
  color: var(--enverusOrange);
}

.controlBody {
  position: absolute;
  top: 24px;
  left: 0px;
  z-index: 1;

  overflow: auto;
}
