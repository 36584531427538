.main {
  min-height: 100vh !important;
}

.header {
  height: unset;
  padding: 0em;
  height: 50px;
  line-height: 50px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .leftHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .rightHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.iconDiv {
  padding: 0px 8px 0px 0px;
}

.icon {
  margin-bottom: 2px;

  border: 1px solid var(--gray);
  background: var(--primaryColor);
  color: var(--gray);
  cursor: pointer;

  &:hover {
    color: var(--secondaryColor);
    border: 1px solid var(--secondaryColor);
  }
}

.portfolio {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}

.signOut {
  border-top: 1px solid var(--borderColor);
}

.avatarDropDownMenu {
  min-width: 150px;
}

.mainContent {
  padding: 0em;
}

.siderNav {
  border-right: 1px solid var(--grayLighter);
}

.svgImage {
  height: 35px;
  width: 70px;
  position: relative;
  align-self: center;
  padding: 0px 10px 0px 10px;
}

.headerLogo {
  background: url("../assets/logo/logo_full_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
}
