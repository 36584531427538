@import "../../common/styles/FormCompact.module.scss";
@import "../../common/styles/Card.module.scss";

.assetMetricsContainer {
  padding: 6px;
  border: 1px solid gainsboro;
  border-style: dashed;
  margin-top: 4px;
}

.assetMetrics {
  width: 100%;
  text-align: left;

  .header {
    border-bottom: 1px dashed gainsboro;
  }
}

.returnRow {
  border-top: 1px dashed gainsboro;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w60 {
  width: 60%;
}

.bold {
  font-weight: bold;
}

.mapContainer {
  height: 600px;
}
