@import "../../common/styles/FormCompact.module.scss";
@import "../../common/styles/Card.module.scss";
@import "../../common/styles/ObjectLines.module.scss";

.collapseCompact {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .amount {
    font-weight: bold;
  }

  div[class="ant-collapse-header"] {
    padding: 5px 10px !important;
  }

  div[class="ant-collapse-content-box"] {
    padding: 0px 5px 0px 30px !important;
  }

  .link {
    color: var(--primaryColor);

    &:hover {
      color: var(--linkColor);
    }
  }
}
