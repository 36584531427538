.controls {
  display: flex;
  flex-grow: 1;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
  justify-content: flex-end;
}

.divider {
  margin: 12px 0px 2px 0px;
}

.spinner {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
