:root {
  --idiBlack: #000;
  --idiBlue: #00adee;
  --idiBlueDark: #0082b3;
  --idiGreen: #237804;
  --idiRed: #f00;
  --idiOrange: #d46b08;
  --idiLightGray: #eaeaea;
  --idiGrayLighter: #f5f5f5;
  --idiGray: #bbb;
  --idiDarkGray: #595959;
}

.main {
  // border: 2px var(--idiGray) solid;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 12px;

  // font-family: Helvetica, Arial, sans-serif;
  margin: 0;
  font-size: 16px;
  color: var(--idiBlack);
}

.loading {
  opacity: 0.5;
}

.section {
  float: left;
  padding: 8px;
  position: relative;
  font-size: 13px;
  line-height: 15px;
}

.horizontalLine {
  content: "";
  position: absolute;
  background-color: var(--idiBlue);
  right: 8px;
  left: 8px;
  bottom: 0;
  height: 1px;
}

.verticalLine {
  content: "";
  position: absolute;
  background-color: var(--idiBlue);
  right: 0;
  top: 8px;
  bottom: 8px;
  width: 1px;
}

.header {
  position: relative;
  display: grid;
  grid-template-columns: 30% 50% 20%;

  background-color: var(--idiLightGray);
  min-height: 166px;

  &:not(:last-child)::after {
    @extend .horizontalLine;
  }

  .personalInfo {
    @extend .section;
    font-size: 18px;
    line-height: 20px;

    &:not(:last-child)::after {
      @extend .verticalLine;
    }

    .nameMain {
      display: flex;
      align-items: center;
    }

    .other {
      font-size: 13px;
      line-height: 15px;
    }

    .age {
      margin-top: 8px;

      b {
        margin-right: 5px;
      }
    }

    .ageNumber {
      margin-right: 5px;
    }
  }

  .personalSummary {
    @extend .section;
    font-size: 13px;
    line-height: 15px;

    &:not(:last-child)::after {
      @extend .verticalLine;
    }

    b {
      margin-right: 5px;
    }
  }

  .taborData {
    @extend .section;
    font-size: 13px;
    line-height: 15px;

    b {
      margin-right: 5px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--idiLightGray);
    }
  }
}

.body {
  position: relative;
  display: grid;
  grid-template-columns: 80% 20%;

  min-height: 276px;

  &:not(:last-child)::after {
    @extend .horizontalLine;
  }

  .addresses {
    @extend .section;

    .address {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--idiLightGray);

      &:hover {
        background-color: var(--idiGrayLighter);
      }

      .addressValidation {
        display: inline-block;
        width: 20px;
      }

      .dateRange {
        display: inline-block;
        width: 150px;
      }

      .monthsDifference {
        display: inline-block;
        width: 35px;
      }

      .bad {
        color: var(--idiRed);
      }

      .warning {
        color: var(--idiOrange);
      }

      .good {
        color: var(--idiGreen);
      }
    }

    &:not(:last-child)::after {
      @extend .horizontalLine;
    }
  }

  .relations {
    @extend .section;

    .relationTypes {
      margin-top: 10px;
    }

    .relationType {
      padding-bottom: 5px;
    }

    .relationHeader {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      border-bottom: 1px solid var(--idiLightGray);
      margin-bottom: 5px;

      .count {
        font-weight: normal;
        border: 1px solid gainsboro;
      }
    }

    .relationBody {
      padding: 0px 14px;
    }

    .relation {
      display: flex;
      justify-content: space-between;
    }
  }
}

.contactInfo {
  @extend .section;
  padding: 0;
  display: flex;
  flex-direction: column;

  &:not(:last-child)::after {
    @extend .verticalLine;
  }
}

.outreachInfo {
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  min-height: 144px;

  &:not(:last-child)::after {
    @extend .horizontalLine;
  }

  .emails {
    @extend .section;

    &:not(:last-child)::after {
      @extend .verticalLine;
    }

    .email {
      display: flex;
    }
  }

  .phones {
    @extend .section;

    &:not(:last-child)::after {
      @extend .verticalLine;
    }

    .phone {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--idiLightGray);

      &:hover {
        background-color: var(--idiGrayLighter);
      }

      .dateRange {
        display: inline-block;
        width: 75px;
        margin-left: 5px;
      }

      .bad {
        color: var(--idiRed);
      }

      .warning {
        color: var(--idiOrange);
      }

      .good {
        color: var(--idiGreen);
      }
    }
  }

  .ipAddresses {
    @extend .section;

    &:not(:last-child)::after {
      @extend .verticalLine;
    }

    .ipAddress {
      display: flex;
    }
  }
}

.link {
  color: var(--idiBlue);
}

.linkReal {
  color: var(--idiBlue);
  &:hover {
    cursor: pointer;
    color: var(--idiBlueDark);
  }
}

.deathIcon {
  width: fit-content;
  font-weight: bold;
  color: white;
  background-color: red;
  padding: 1px 3px 0 3px;
  margin-right: 3px;
  margin-bottom: 3px;
}

.pidLink {
  margin-left: 5px;
  font-size: 15px;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.marginRightSmall {
  margin-right: 3px;
}

.lookupNames {
  @extend .linkReal;
  margin-left: 5px;
}

.twilioExtras {
  @media (max-width: 1600px) {
    display: none;
  }
}
