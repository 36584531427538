@import "../../common/styles/FormCompact.module.scss";
@import "../../common/styles/Card.module.scss";
@import "../../common/styles/CardTabs.module.scss";

.chartContainer {
  height: 300px;
  overflow: hidden;
}

.emptyChart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
