@import "../../common/styles/FormCompact.module.scss";
@import "../../common/styles/Card.module.scss";

@import "../../common/styles/FormInput.module.scss";

.objectData {
  @extend .formInput;
  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
  }

  div {
    flex-grow: 1;
  }
}

// .linkButton {
//   border: 1px solid var(--borderColor);
//   padding: 0px 3px;
// }

// .linkButton:hover {
//   border: 1px solid var(--linkColor);
// }

.clickInput {
  width: 22px !important;
  height: 22px !important;
  transform: translateY(1px) translateX(4px);
}
