.card {
  margin: 0em 5px 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .cardTitle {
    border-bottom: 1px solid var(--grayLighter);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: var(--cardHeaderHeight);

    .leftTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 10px;
    }

    .rightTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;

      .cardHeaderAction {
        margin-left: 8px;
      }
    }
  }

  .cardBody {
    padding: 10px;
  }
}
