.renderedArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.form {
  padding-right: 1em;
}

.fullHeight {
  height: 100%;

  div[class="ant-tabs-content ant-tabs-content-top"] {
    height: 100%;
  }
}

.paddingBottom {
  padding-bottom: 20px;
}

.editor {
  border: 1px solid gainsboro;
}

.marginRight {
  margin-right: 10px;
}

.pdfContainer {
  height: calc(100% - 20px);
}
