.container {
  row-gap: 2px;
  column-gap: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.label {
  &:not(:first-child) {
    margin-left: 5px;
  }
}
