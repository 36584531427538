.container {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
}

.header {
  background-color: #454545;
  color: white;
  padding: 2px 2px 2px 9px;
  margin: 2px 0px 2px 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  caret-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #777;
  }

  &.active {
    background-color: #005af6;
  }
}

.headerDisabled {
  cursor: default;
  opacity: 0.75;
  // background-color: #333;
  pointer-events: none;
}

.filterIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 18px;
  width: 18px;

  background-color: blue;

  &:hover {
    cursor: pointer;
    color: #a0ef6e;
  }

  svg {
    color: #55bc2e;
  }
}

.clearIcon {
  cursor: pointer;
  width: 12px;
  height: 12px;

  &:hover {
    color: #005af6;
  }
}

.search {
  padding: 5px 0px 5px 0px;

  .input {
    font-size: 12px;
  }
}

.resultsContainer {
  display: flex;
  flex-direction: column;

  background-color: #000;
}

.resultsContainerLoading {
  pointer-events: none;
  opacity: 0.5;
}

.results {
  max-height: 145px;
  overflow-y: auto;
  caret-color: transparent;
  cursor: pointer;

  .result {
    padding: 2px 5px;
    color: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:hover {
      background-color: #757575;
    }

    .count {
      letter-spacing: 0px;
      font-size: 11px;
    }
  }
}

.selectedResult {
  max-height: 145px;
  overflow-y: auto;
  caret-color: transparent;
  cursor: pointer;

  .result {
    padding: 2px 5px;
    background-color: #56bc2f;
    color: black;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:hover {
      color: white;
      background-color: #f00;
    }

    .count {
      letter-spacing: 0px;
      font-size: 11px;
    }
  }

  .actionIcon {
    margin-right: 5px;
  }
}
