@import "../../common/styles/CSVDownload.module.scss";
@import "../../common/styles/Card.module.scss";
@import "../../common/styles/CardTabs.module.scss";

.taxYearDropDown {
  margin-right: 1em;
}

.badInterest {
  color: var(--errorColor);
}

.columnExtras {
  margin-right: 8px;
}

.mapLoading {
  height: 300px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  padding: 2px;
  height: 600px;
}

.notesContainer {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  padding: 0px 10px;
  height: 600px;
}
