:root {
  --enverusButtonGreen: #56bc2f;
  --enverusButtonGreenLight: #a0ef6e;
}

.popup {
  div[class="mapboxgl-popup-content"] {
    padding: 0;
  }
}

.container {
  max-width: 400px;
  max-height: 400px;
  overflow: auto;
}

.header {
  background-color: var(--enverusBlack);
  text-align: center;
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  height: 20px;

  display: flex;
  flex-direction: column;

  .rightAction {
    position: absolute;
    right: 0;
    cursor: pointer;
    width: 20px;
    text-align: center;
    z-index: 1;
  }
}

.body {
  padding: 5px;
}

.footer {
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.button {
  color: var(--enverusBlack);
  background-color: var(--enverusButtonGreen);

  &:hover {
    color: var(--enverusBlack);
    background-color: var(--enverusButtonGreenLight);
  }

  &:focus {
    color: var(--enverusBlack);
    background-color: var(--enverusButtonGreenLight);
  }
}
