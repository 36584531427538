@import "../common/styles/Card.module.scss";

.disabledItem {
  color: black !important;
}

.columnExtras {
  margin-right: 8px;
}

.table {
  table {
    width: auto;
  }
}
