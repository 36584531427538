.control {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 300px;
  font-size: 12px;
  color: white;
  background-color: var(--enverusDarkGreen);
}

.controlHeader {
  border-top: 4px solid var(--enverusGreen);
  border-bottom: 2px solid var(--enverusGray);
  background-color: var(--enverusDarkGreen);
  color: white;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  font-size: 14px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  caret-color: transparent;

  > div:first-child {
    width: 24px;
  }
}

.controlBody {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 8px 8px 10px 8px;
}

.controlCollapseButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
}

.switch {
  margin-right: 5px;
}

.switchOn {
  background-color: var(--enverusOrange);
}

.switchOff {
  background-color: var(--enverusGray);
}

.invisible {
  display: none;
}
