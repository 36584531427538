.loading {
  opacity: 0.5;
}

.idiPersonCard {
  margin: 10px 0px;
}

.results {
  background-color: #38b24a;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
}

.error {
  margin: 1em 0em 0em 0em;
  padding: 1em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
