.objectLines {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .leftTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0.75em 0em;
    }

    .rightTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0.75em 0em;
    }
  }

  .content {
  }
}
