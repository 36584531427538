@import "../../common/styles/FormInput.module.scss";

.objectData {
  @extend .formInput;
  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
  }

  div {
    flex-grow: 1;
  }
}

.lastPrediction {
  @extend .formInput;
  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
  }

  .decile {
    margin-left: 8px;
  }
}

.previouslyOffered {
  display: flex;
  align-items: center;

  .warning {
    color: var(--warningColor);
  }

  .oldId {
    margin-left: 4px;
  }
}

.extraData {
  display: flex;
  gap: 5px;
}

.linkButton {
  border: 1px solid var(--borderColor);
  padding: 0px 3px;
}

.linkButton:hover {
  border: 1px solid var(--linkColor);
}
