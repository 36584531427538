.editableRow:hover {
  .editableCell {
    border: 1px solid #d9d9d9;
    margin: -1px;
  }
}

.resizeHeader {
  position: relative;

  span {
    position: absolute;
    width: 20px;
    height: 100%;
    bottom: 0;
    right: -16px;
    cursor: col-resize;
    z-index: 1;
    pointer-events: fill;
  }
}

.EditableCellColumn {
  .EditableCellFormItem {
    div {
      line-height: unset;
    }
  }
}

.fixSelection {
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.formItem {
  div[class="ant-form-item-control-input"] {
    min-height: 24px;
  }
}

.disabledItem {
  color: black !important;
}
