.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // pointer-events: none;
  user-select: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 400px;
    height: 200px;
  }

  .loadingText {
    margin-top: 20px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
  }

  .loadingSubText {
    margin-top: 10px;
    text-align: center;
    letter-spacing: 1px;
    font-size: 14px;
    pointer-events: unset;
  }
}
