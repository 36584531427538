@import "../../common/styles/FormCompact.module.scss";
@import "../../common/styles/Card.module.scss";
@import "../../common/styles/FormInput.module.scss";

.spinner {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolTipCard {
  div[class="ant-tooltip-content"] {
    width: 500px;
  }
}

.actionsColumn {
  width: 32px;
}
