:root {
  --enverusBlack: #191919;
  --enverusGray: #515151;
  --enverusLightGray: #999;
  --enverusOrange: #ff5f23;
  --enverusGreen: #8cda2a;
  --enverusDarkGreen: #03252f;
  --enverusBlue: #005af6;
}

:global {
  .mapboxgl-ctrl-attrib-inner {
    display: none;
  }

  .mapboxgl-canvas {
    outline: none !important;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-scale {
    margin-bottom: 0px;
  }

  a.mapboxgl-ctrl-logo {
    display: none;
  }

  /* Hide the third instance of mapboxgl-ctrl-group */
  .mapboxgl-ctrl-group.mapboxgl-ctrl:nth-of-type(3) {
    display: none;
  }

  /* Full screen control */
  .mapboxgl-ctrl-top-right {
    position: absolute;
    top: 1px;
    right: 1px;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    margin: 0px;
    border-radius: 0px;
    box-shadow: none;
    outline: 1px solid black;
    margin-bottom: 5px;
  }

  .mapboxgl-ctrl-group button {
    height: 22px;
    width: 22px;
  }
}

.mapContainer {
  height: 100%;
  position: relative;
}

.map {
  height: 100%;
  width: 100%;
  background-color: lightgrey;
  z-index: 1;
}

.mapLoading {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
