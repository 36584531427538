@import "../../common/styles/FormCompact.module.scss";

.section {
  border-top: 1px dashed #d9d9d9;
  padding: 16px 0px;
}

.validationSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.error {
  color: #ff4d4f;
}
