.formInput {
  input {
    width: 100%;

    background-color: unset;
    color: var(--primaryColor);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px !important;
    border-bottom: 1px solid var(--primaryColor);

    &:hover {
      background: unset;
      color: var(--primaryColor);
      border-top: 0px;
      border-left: 0px;
      border-right: 0px !important;
      border-bottom: 1px solid var(--primaryColor);
    }

    &:focus {
      border-color: var(--primaryColor);
      border-right-width: 0px !important;
      outline: 0;
      box-shadow: none;
    }
  }

  .inputDisabled {
    background-color: unset;
    border-style: dashed;
    color: var(--primaryColor);
    &:hover {
      color: var(--primaryColor);
      border-color: var(--primaryColor);
      border-style: dashed;
      cursor: pointer;
    }
  }

  .inputActive {
    border-style: solid;
  }
}
