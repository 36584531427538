.marginRight {
  margin-right: 10px;
}

.toolTipCard {
  div[class="ant-tooltip-content"] {
    width: 500px;
  }
}

td.rowLinkNotLinked {
  .rowIcon {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
