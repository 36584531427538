.main {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 10px;
  border: 1px solid gainsboro;

  display: flex;
  flex-direction: column;
}

.notificationTitle {
  font-weight: bold;
}

.notificationBody {
  font-size: 14px;
}

.rqDash {
  flex-grow: 1;
}
