.optionSimple {
  display: flex;
  flex-direction: row;

  .main {
    font-weight: bold;
    margin-right: 5px;
  }
  .extra {
    font-size: 12px;
  }
}
