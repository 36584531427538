.searchDropDown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;

  button {
    font-size: 12px;
  }

  input {
    font-size: 12px;
  }
}

.filterButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    position: inherit !important;
    color: unset !important;
  }
}
