@import "./Filters.module.scss";

.main {
  padding-bottom: 10px;
  min-width: 400px;
}

.selectAddOn {
  span[class="ant-select-selection-item"] {
    padding-right: 0px !important;
  }
  width: 130px;
}

.selectValue {
  width: 100%;
}
