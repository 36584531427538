.cardTabs {
  margin: 0em 5px 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .cardTitle {
    border-bottom: 1px solid var(--grayLighter);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: var(--cardHeaderHeight);

    .leftTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;

      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: thin;
      padding-right: 20px;

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background: var(--grayLighter);
        border-radius: 4px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: var(--grayLight);
      }

      div {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }

      .isActive {
        border-bottom: 2px solid var(--primaryColor);
      }
    }

    .rightTitle {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;

      .cardHeaderAction {
        margin-left: 5px;
      }
    }
  }

  .cardBody {
    padding: 10px;
  }
}
