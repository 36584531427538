@import "./ControlsBase.module.scss";
@import "../../../common/styles/FormCompact.module.scss";

.ctaContainer {
  padding: 2px 0px 6px 0px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;

  .ctaButton {
    width: 100%;
    border-radius: 0px;
    color: black;
  }

  .resetButton {
    @extend .ctaButton;
    background-color: var(--enverusLightGray);

    &:hover {
      background-color: #bababa;
    }
  }

  .applyButton {
    @extend .ctaButton;
    background-color: #56bc2f;

    &:hover {
      background-color: #a0ef6e;
    }
  }
}
