@import "./FormInput.module.scss";

.objectData {
  @extend .formInput;

  padding: 0em 8px;
  margin-bottom: 10px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
    white-space: nowrap;
  }

  div {
    flex-grow: 1;
  }

  .idContainer {
    font-size: 12px;
  }
}
