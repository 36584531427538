.main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5px;
  background-color: white;
  margin-left: 10px;

  cursor: pointer;
  caret-color: transparent;

  &:hover {
    background-color: #f0f0f0;
  }
}

.label {
  &:not(:first-child) {
    margin-left: 5px;
  }
}

.emptyLabels {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;

  span {
    margin-left: 5px;
  }
}
