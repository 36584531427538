.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .waterfallChart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    svg {
      height: 480px;
      max-width: 712px;
    }
  }
}
