.linkNoColor {
  a {
    color: unset !important;
  }
}

.uploadButton {
  margin-right: 10px;
}

.toolTipCard {
  div[class="ant-tooltip-content"] {
    width: 500px;
  }
}
