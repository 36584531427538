@import "../../common/styles/FormInput.module.scss";

.objectData {
  @extend .formInput;
  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
  }

  div {
    flex-grow: 1;
  }
}

.legalDescription {
  border-top: 1px solid gainsboro;
  padding-top: 10px;
}

.previouslyOffered {
  display: flex;
  align-items: center;

  .warning {
    color: var(--warningColor);
  }

  .oldId {
    margin-left: 4px;
  }
}
