.main {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  padding: 20px 0px;
  margin: 10px;
  border: 1px solid gainsboro;
}

.hoverLink {
  position: absolute;
  top: 75px;
  right: 40px;
}
