@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");

/* variables */
:root {
  --fontSize: 14px;

  /* colors */
  --primaryColor: #1c1c1c;
  --secondaryColor: #fff;
  --gray: #7f888f;
  --grayLighter: #e8e8e8;
  --linkColor: #209cee;
  --linkColorDark: #0e72b4;
  --successColor: #42bb6c;
  --warningColor: #ffdd57;
  --warningColorDark: #faad14;
  --errorColor: #d74945;
  --borderColor: #d9d9d9;

  /* https://coolors.co/user/palettes/5f8707c4dea8be000a5e68cf */
  --red: #d74945;
  --pink: #fc6c85;
  --purple: #721ea9;
  --green: #4bbf90;
  --orange: #f0ad4e;
  --blue: #317ae2;
  --yellow: #ffdf5c;

  --cardHeaderHeight: 44px;
}

/* base html */
html {
  font-size: var(--fontSize);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

body {
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: var(--linkColorDark);
  text-decoration: none;

  &:hover {
    color: var(--linkColor);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  text-transform: uppercase;
}

h1 {
  font-size: calc(var(--fontSize) * 1.2);
  font-weight: 700;
  letter-spacing: 2px;
}

h2 {
  font-size: calc(var(--fontSize) * 1.1);
  font-weight: 600;
  letter-spacing: 2px;
}

h3 {
  font-size: var(--fontSize);
  font-weight: 600;
  letter-spacing: 2px;
}

/* Widths */
.App__w6 {
  width: 60px;
}

.App__w7 {
  width: 70px;
}

.App__w8 {
  width: 80px;
}

.App__w9 {
  width: 90px;
}

.App__w10 {
  width: 100px;
}

.App__w12 {
  width: 120px;
}

.App__w14 {
  width: 140px;
}

.App__w16 {
  width: 160px;
}

.App__w18 {
  width: 180px;
}

.App__w20 {
  width: 200px;
}

.App__w30 {
  width: 300px;
}

.App__w40 {
  width: 400px;
}

.App__fullWidth {
  width: 100%;
}

.App__ml-4 {
  margin-left: 4px;
}

.App__ml-5 {
  margin-left: 5px;
}

.App__ml-8 {
  margin-left: 8px;
}

.App__mr-4 {
  margin-right: 4px;
}

.App__mr-5 {
  margin-right: 5px;
}

.App__mr-8 {
  margin-right: 8px;
}

.App__mb-10 {
  margin-bottom: 10px;
}

/* layouts */
.App__nthRow {
  padding-top: 10px;
}

.App__rowContent {
  padding: 10px 10px 0em 10px;
}

.App__rowContentDetail {
  padding: 10px 5px 0px 5px;
}

.App__pagination {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.App__clickable {
  cursor: pointer;
}

.App__tableEditButton {
  margin-right: 5px;
}

.App__searchFooterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0px 0px 0px;
}

.App__iconButton {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.App__rightActions {
  display: flex;
  justify-content: flex-end;
}

.App__tableOperations {
  display: flex;
}

/* Search tags */
.App__searchTags {
  padding: 5px;
  font-size: 14px;
  background: unset;
}

.App__searchTags:hover {
  cursor: pointer;
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
}

.App__searchTags:hover * svg {
  color: var(--primaryColor);
}

.App__searchTags * svg {
  margin-left: 5px;
}

/* Small tables */
.App__smallTables * {
  font-size: 12px;
}

.App__smallTables div[class="ant-spin ant-spin-spinning"] * {
  font-size: 24px;
}

.App__smallTables th[class*="ant-table-cell"] {
  font-weight: 700;
}

.App__smallTables .ant-pagination {
  margin: 8px 0px 0px 0px;
}

/* Table icon links */
td.App__rowLink:hover {
  cursor: pointer;
  color: var(--linkColor);
}

td.App__rowLink .App__rowIcon {
  display: flex;
  align-items: center;
  flex-direction: column;
}

td.App__rowLinkNamed:hover {
  cursor: pointer;
  color: var(--linkColor);
}

td.App__rowLinkNamed .App__rowIcon {
  display: flex;
  flex-direction: row;
}

.App__toolTipCard div[class="ant-tooltip-content"] {
  width: 500px;
}

.ql-container {
  font-size: 14px;
}

.App__tableIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div[class="ant-empty ant-empty-normal"] {
  margin: 5px 0px 0px 0px;
}

sup[class*="ant-scroll-number ant-badge-count"] {
  margin-top: 0px !important;
  right: -20px !important;
}
