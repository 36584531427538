@import "../common/styles/FormInput.module.scss";
@import "../common/styles/Card.module.scss";

.objectData {
  @extend .formInput;

  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  span {
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
    flex-grow: 0;
  }

  .objectLink {
    display: flex;
    align-self: center !important;
    font-weight: 400;
    color: var(--linkColorDark);

    &:hover {
      cursor: pointer;
      color: var(--linkColor);
    }
  }
}

.compactForm {
  div[class="ant-form-item"] {
    margin-bottom: 12px;
  }

  div[class*="ant-form-item ant-form-item"] {
    margin-bottom: 12px;
  }

  input[class=".ant-input-number-disabled"] {
    background-color: unset;
  }
}
