.container {
  border: 1px solid gainsboro;
  margin-top: 10px;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  padding: 6px 12px;
  border-bottom: 1px solid gainsboro;

  .userEditInfo {
    display: flex;

    .date {
      margin-right: 5px;
    }
  }
}

.footer {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
  justify-content: flex-end;
}
