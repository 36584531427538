@import "../../common/styles/FormCompact.module.scss";
@import "../../common/styles/Card.module.scss";

@import "../../common/styles/FormInput.module.scss";

.title {
  white-space: nowrap;
}

.objectData {
  // @extend .formInput;
  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
  }

  div {
    flex-grow: 1;
  }

  span[class*="ant-select-selection-item"] {
    color: black;
    background: white !important;
    cursor: not-allowed;
  }

  div[class*="ant-select-selector"] {
    background: white !important;
  }
}
