.selectOption {
  width: 100%;

  > div[class*="ant-select"] {
    margin: 0;
    margin-bottom: 0px !important;
    width: unset !important;
  }

  div[class="ant-select-selection__placeholder"] {
    font-weight: 400 !important;
  }
}

.booleanInput {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
}

.searchTextInput {
  font-weight: 400 !important;
}
