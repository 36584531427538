.gridPosition {
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  top: 19px;
  right: 28px;
  z-index: 1;
  font-size: 12px;
  background-color: white;

  border: 1px solid black;
  padding: 0px 5px;
}

.hidden {
  display: none;
}
