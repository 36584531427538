.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;

  svg {
    height: 14px;
    width: 14px;
  }
}
