@import "../common/styles/FormInput.module.scss";
@import "../common/styles/CSVDownload.module.scss";
@import "../common/styles/Card.module.scss";

.objectData {
  @extend .formInput;

  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
  }

  div {
    flex-grow: 1;
  }
}

.linkNoColor {
  a {
    color: unset;
  }

  a:hover {
    color: var(--linkColor);
  }
}

.objectLink {
  display: flex;
  align-self: center !important;
  font-weight: 400;
  flex-grow: 0 !important;
  width: 25px;
  &:hover {
    cursor: pointer;
    color: var(--linkColor);
  }
}

.toolTipCard {
  div[class="ant-tooltip-content"] {
    width: 500px;
  }
}
