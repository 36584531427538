@import "../../common/styles/FileUpload.module.scss";

.line {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 10px;

  .childWell {
    flex-grow: 3;
  }

  .childAmount {
    width: 200px;
    translate: 0 -4px;

    div[class*="ant-input-number-affix-wrapper"] {
      width: 100%;
    }
  }
}

.allocationTable {
  margin-bottom: 20px;
}

.extraSpacing {
  padding: 0 0 16px;
}

.section {
  border-top: 1px dashed #d9d9d9;
  padding: 16px 0px;
}

.sectionHeading {
  padding: 0 0 8px;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.error {
  color: #ff4d4f;
}

.twoColumns {
  display: grid;
  width: 100%;
  grid-gap: 2%;

  grid-template-columns: 49% 49%;
}
