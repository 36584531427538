.objectLink {
  display: flex;
  align-self: center;
  font-weight: 400;
  color: var(--linkColorDark);
  background-color: #f5f5f5;

  padding: 0px 3px;
  border: 1px solid #d9d9d9;

  &:hover {
    cursor: pointer;
    color: var(--linkColor);
  }
}

.compactForm {
  div[class="ant-form-item"] {
    margin-bottom: 0px;
  }

  div[class*="ant-form-item ant-form-item"] {
    margin-bottom: 0px;
  }

  input[class=".ant-input-number-disabled"] {
    background-color: unset;
  }
}

.numberInput {
  min-width: 200px;
}

.disabledItem {
  color: black !important;

  div[class="ant-select-selector"] {
    color: black !important;
  }
}
