@import "../../common/styles/Card.module.scss";
@import "../maps/MapContainer.module.scss";

.card {
  @extend .card;
  height: calc(100% - 10px);

  .cardBody {
    @extend .cardBody;
    display: flex;
    flex-direction: column;

    padding: 0;
    height: calc(100% - var(--cardHeaderHeight));
    min-height: 800px;
  }
}
