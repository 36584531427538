.csvDownload {
  margin-right: 8px;
  border: 1px solid var(--borderColor);
  padding: 0.3em;
  color: var(--gray);

  &:hover {
    border: 1px solid var(--primaryColor);
    color: var(--primaryColor);
  }
}
