@import "../ControlsBase.module.scss";

.layer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: white;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;

  .label {
    margin-right: 5px;
  }
}

.layerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  height: 22px;
}

.layerBody {
  display: flex;
  flex-direction: column;
  width: 100%;
}
