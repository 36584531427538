.bulkEditButton {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.bulkDeleteButton {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.mailExportButton {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.noPointerEvents {
  pointer-events: none;
}

.iconBulkSearchButton {
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;

  display: flex;
  align-items: center;
}
