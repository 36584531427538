@import "../../common/styles/FileUpload.module.scss";
@import "../../common/styles/FormCompact.module.scss";

.grantee {
  flex-grow: 1;
}

.line {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 6px 0px;
}

.section {
  border-top: 1px dashed #d9d9d9;
  padding: 16px 0px;
}

.validationSection {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.error {
  color: #ff4d4f;
}

.compactList {
  div[class*="ant-form-item"] {
    margin-bottom: 0px;
  }
}

.addLine {
  margin-top: 16px;
}

.minusIcon {
  padding-top: 30px;
}
