.checkBoxGroup {
  width: 100%;
}

.list {
  width: 100%;
  border: 1px solid gainsboro;

  div[class="ant-row ant-legacy-form-item"] {
    margin-bottom: 0px;
    padding: 0px 15px;
    border-bottom: 1px solid gainsboro;

    div[class*="ant-legacy-form-item-label"] {
      display: flex;
    }

    div[class*="ant-legacy-form-item-control-wrapper"] {
      display: flex;
      justify-content: flex-end;
    }
  }
}
