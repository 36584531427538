@import "../ControlsBase.module.scss";
@import "../../../../common/styles/FormCompact.module.scss";

.layersContainer {
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.zoomToFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;

  padding-bottom: 6px;
  border-bottom: 1px solid var(--enverusGray);
  margin-bottom: 6px;
}

.layer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  color: white;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;

  .label {
    margin-right: 5px;
  }
}

.layerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  height: 22px;
}

.layerBody {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.filterButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 15px;
  width: 15px;

  margin-right: 5px;
  background-color: var(--enverusGreen);
  color: var(--enverusBlack);

  &:hover {
    cursor: pointer;
  }
}

.filterCtaContainer {
  padding: 5px 0px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .ctaButton {
    width: 100%;
    border-radius: 0px;
    color: black;

    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .resetButton {
    @extend .ctaButton;
    background-color: var(--enverusLightGray);

    &:hover {
      background-color: #bababa;
    }
  }

  .applyButton {
    @extend .ctaButton;
    background-color: #56bc2f;

    &:hover {
      background-color: #a0ef6e;
    }
  }
}

.filterClose {
  flex-grow: 1;
  display: flex;
  justify-content: end;
}

.filterCloseButton {
  height: 20px;
  width: 20px;
  padding: 2px;
  background-color: var(--enverusBlack);
  border: 1px solid var(--enverusGreen);

  &:hover {
    cursor: pointer;
    background-color: blue;

    svg {
      path {
        fill: var(--enverusGreen);
        stroke: var(--enverusGreen);
      }
    }
  }

  svg {
    height: 100%;
    width: 100%;

    path {
      fill: #55bc2e;
      stroke: #55bc2e;
    }
  }
}
