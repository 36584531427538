.objectData {
  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  > span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
  }

  div {
    flex-grow: 1;
  }
}

.linkNoColor {
  a {
    color: unset;
  }

  a:hover {
    color: var(--linkColor);
  }
}

.legalDescription {
  border-top: 1px solid gainsboro;
  padding-top: 10px;
}
