.container {
  row-gap: 2px;
  column-gap: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.pipedriveLabel {
  color: white;
  font-weight: 700;
  white-space: nowrap;
  padding: 0px 4px;
  border-radius: 2px;
  width: fit-content;
  flex-grow: 0 !important;
  flex-wrap: wrap;
  height: fit-content;

  &:hover {
    cursor: pointer;
  }
}

.blackColor {
  color: black;
}
