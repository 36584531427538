@import "../common/styles/CSVDownload.module.scss";
@import "../common/styles/Card.module.scss";

.objectData {
  padding: 0em 5px;
  margin-bottom: 5px;
  color: var(--primaryColor);

  display: flex;

  span {
    font-weight: 700;
    margin-right: 8px;
    display: flex;
    align-self: flex-end;
    flex-grow: 0;
  }

  .objectLink {
    display: flex;
    align-self: center !important;
    font-weight: 400;
    &:hover {
      cursor: pointer;
      color: var(--linkColor);
    }
  }
}
