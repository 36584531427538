.notification {
  padding: 5px 8px 10px 8px;
  width: auto;
  max-width: 800px;

  div[class="ant-notification-notice-message"] {
    margin-bottom: 5px;
    margin-left: 0px;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid gainsboro;
    padding-bottom: 5px;
  }

  div[class="ant-notification-notice-description"] {
    font-weight: 400;
    font-size: 12px;
    margin: 0px;
  }

  a[class="ant-notification-notice-close"] {
    top: 5px;
    right: 8px;
    border: 1px solid gainsboro;
    padding: 0px 2px;
  }
}

.properties {
  width: 200px;
  overflow-x: auto;
  scrollbar-width: thin;

  .property {
    white-space: nowrap;
  }

  .loading {
    opacity: 0.5;
  }
}

.separator {
  margin: 5px 0;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.unitsButton {
  flex-grow: 1;
}
