@import "../../common/styles/Card.module.scss";

.loading {
  opacity: 0.5;
}

.owners {
  margin: unset;
}

.jsonView {
  margin: 0em;
  padding: 1em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
