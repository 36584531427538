.base {
  width: fit-content;
  font-weight: bold;
  padding: 0px 3px 0 3px;

  white-space: nowrap;
}

.dead {
  width: fit-content;
  font-weight: bold;
  color: white;
  background-color: var(--red);
  padding: 0px 3px 0 3px;
}

.decile {
  border-radius: 10px;
  padding: 0px 5px;
  // height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assetCount {
  font-size: 10px !important;

  padding: 0px 5px;
  // height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 10px;
  border: 0.5px solid var(--primaryColor);

  color: var(--primaryColor);
  background-color: var(--grayLighter);
}
