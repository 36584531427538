@import "../../common/styles/CSVDownload.module.scss";
@import "../../common/styles/Card.module.scss";
@import "../../common/styles/CardTabs.module.scss";

.taxYearDropDown {
  margin-right: 1em;
  width: 90px;
}

.badInterest {
  color: var(--errorColor);
}

.columnExtras {
  margin-right: 8px;
}

.idiPerson {
  margin: 0em 0.5em 1em;
}

.notificationBody {
  font-size: 14px;
}
