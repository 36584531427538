@import "../../common/styles/Card.module.scss";
@import "../maps/MapContainer.module.scss";

.main {
  height: calc(90vh);
  max-height: 1400px;
  min-height: 800px;
}

.card {
  @extend .card;
  height: calc(100% - 14px);

  .cardBody {
    @extend .cardBody;
    display: flex;
    flex-direction: column;

    padding: 0;
    height: calc(100% - var(--cardHeaderHeight));
  }
}
