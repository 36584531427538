.fileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 16px;
  border: 1px solid var(--grayLighter);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .iconContainer {
    height: 30px;
    width: 30px;
    margin: 20px 16px 20px 10px;

    .icon {
      font-size: 30px;
      color: lighten(#1c1c1c, 10%);
    }
  }

  .file {
    flex: 1;
    padding-right: 10px;
  }

  .spaced {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      font-size: 13px;
    }

    :nth-child(1) {
      flex: 1;
    }
    :nth-child(2) {
      flex: 0;
    }
  }

  .gray {
    color: lighten(#1c1c1c, 15%);
  }
}

.hide {
  display: none;
}
