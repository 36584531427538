.editor {
  div[class*="ql-container ql-snow"] {
    border: unset;
  }
}

.editorEdit {
  div[class*="ql-container ql-snow"] {
    border: 1px solid #ccc;
  }
}

.notEditorExpanded {
  max-height: 30px;
  overflow: hidden;
  margin-bottom: 14px;
}
