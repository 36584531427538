@import "../../main/MainLayout.module.scss";

.countyCount {
  display: flex;
  align-items: center;

  .countyCountLabel {
    margin-right: 8px;
  }
}
