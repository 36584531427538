@import "../../common/styles/FormCompact.module.scss";

.checkBoxGroup {
  width: 100%;
}

.leadList {
  overflow: auto;
  max-height: 300px;
  width: 100%;

  .leadListItem {
    display: flex;
    flex-direction: row;
    width: 100%;

    .itemRight {
      display: flex;
      align-self: flex-end;
      flex-grow: 1;
      justify-content: flex-end;
    }

    .itemLeft {
    }
  }
}
