.optionSimple {
  display: flex;
  flex-direction: row;

  .main {
    font-weight: bold;
    margin-right: 5px;
  }
  .extra {
    font-size: 12px;
  }
}

.addNew {
  display: flex;
  gap: 8px;
  padding: 0 8px 4px;
  width: 100%;
}
