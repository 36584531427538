.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.control {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 22px;
  width: 22px;

  &:not(:last-child) {
    border-bottom: 1px solid gainsboro;
  }
}

.antdIcon {
  svg {
    height: 16px;
    width: 16px;
  }
}

.active {
  path {
    fill: #ff5f23;
    stroke: #ff5f23;
  }

  rect {
    stroke: #ff5f23;
  }

  circle {
    fill: #ff5f23;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
