.recordCountStat {
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    color: var(--secondaryColor);
    background-color: var(--gray);
    padding: 0em 10px;
    letter-spacing: 1px;
    min-width: max-content;
  }

  .metric {
    background-color: var(--grayLighter);
    padding: 0em 10px;
  }
}

.tempCount {
  display: flex;
  flex-direction: row;

  .loadingCount {
    margin-left: 8px;
  }
}
