@import "../../common/styles/FileUpload.module.scss";

.modal {
  div[class="ant-modal"] {
    top: 16px;
  }
}

.columnSection {
  border-top: 1px dashed #d9d9d9;
  padding: 16px 0px;
}

.columnSectionHeading {
  padding: 0 0 8px;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.columnMapper {
  border: 1px solid var(--grayLighter);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 4px 16px 4px 16px;
}

.columnMapperEmpty {
  padding: 16px;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 0px;
}

.columnMapperTitle {
  display: grid;
  grid-template-columns: 30% 30% 40%;
  justify-content: space-between;
  padding: 10px 0px;
  padding: 5px 0px;
}

.title {
  font-weight: bold;
}

.columnMapperRow {
  @extend .columnMapperTitle;
  border-top: 1px solid gainsboro;
}

.columnMapperCell {
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  div[class*="ant-form-item"] {
    margin-bottom: 0px;
  }
}

.text {
  margin-right: 8px;
}

.parseError {
  color: var(--errorColor);
}

.validationErrors {
  border-top: 1px dashed #d9d9d9;
  padding: 16px 0px;
  margin-top: 16px;
}
