.compactForm {
  div[class*="ant-form-item-label"] {
    padding: 2px;
  }

  div[class="ant-form-item"] {
    margin-bottom: 8px;
  }

  div[class*="ant-form-item ant-form-item"] {
    margin-bottom: 8px;
  }

  input[class=".ant-input-number-disabled"] {
    background-color: unset;
  }
}

.addressColumns {
  display: grid;
  width: 100%;
  grid-gap: 2%;

  grid-template-columns: 50% 23% 23%;
}

.twoColumns {
  display: grid;
  width: 100%;
  grid-gap: 2%;

  grid-template-columns: 49% 49%;
}
