@import "./ControlsBase.module.scss";

.satellite {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;

  padding-bottom: 6px;
  border-bottom: 1px solid var(--enverusGray);
  margin-bottom: 6px;
}
