.multiTook {
  display: flex;
}

.tookStat {
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:nth-of-type(2) {
    margin-left: 8px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--grayLighter);
  }

  div:nth-child(1) {
    color: var(--primaryColor);
    background-color: var(--grayLighter);
    padding: 0em 10px;
    letter-spacing: 1px;
  }

  div:nth-child(2) {
    padding: 0em 10px;
  }
}
