.mainLogin {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.loginForm {
  max-width: 400px;
  width: 100%;

  .loginFormForgot {
    float: right;
  }

  .loginFormButton {
    width: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    &:hover {
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.loginSvgImage {
  height: 8em;
  width: 16em;
  position: relative;
  align-self: center;
  padding: 1em;
}

.loginLogo {
  background: url("../assets/logo/logo_full_black.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.loginFooter {
  padding-top: 1em;
  .forgotPassword {
    float: right;
  }
  .register {
    margin-left: 8px;
  }
}
