.line {
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 8px;

  .childWell {
    flex-grow: 3;
  }

  .childAmount {
    width: 200px;
    translate: 0 -4px;

    div[class*="ant-input-number-affix-wrapper"] {
      width: 100%;
    }
  }
}

.section {
  border-top: 1px dashed #d9d9d9;
  padding: 16px 0px;
}

.sectionHeading {
  padding: 0 0 8px;

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}

.error {
  color: #ff4d4f;
}

.disabledItem {
  color: black !important;

  div[class="ant-select-selector"] {
    color: black !important;
  }
}

.compactList {
  div[class*="ant-form-item"] {
    margin-bottom: 0px;
  }
}

.amount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 10px;

  .amountField {
    flex-grow: 1;
  }
}
